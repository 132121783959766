@use '@angular/material' as mat;

@mixin typography-theme($theme) {
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    $icon-color: map-get($foreground, icon);
    // $icon-color: mat.get-color-from-palette(fuseColors.$fuse-navy, 500);

    a {
        color: map-get($accent, default);
    }

    // Mat-Button - Icon color
    button {
        &[mpx-button],
        &[mat-icon-button] {
            &:not(.primary):not(.accent):not(.warn):not([disabled='true']) {
                mat-icon {
                    color: $icon-color;
                }
            }
        }
    }

    // Mat-Form-Field - Icon color
    mat-form-field {
        &.mat-form-field-disabled {
            mat-icon {
                color: map-get($foreground, disabled);
            }
        }

        &:not(.mat-form-field-disabled) {
            mat-icon {
                color: $icon-color;
            }
        }
    }

    // Mat-Menu - Icon color
    .mat-mdc-menu-panel {
        button {
            &.mat-mdc-menu-item {
                &:not([disabled='true']) {
                    mat-icon {
                        color: $icon-color;
                    }
                }
            }
        }
    }

    .text-boxed {
        @if ($is-dark) {
            background-color: rgba(255, 255, 255, 0.12);
            color: rgba(255, 255, 255, 0.54);
        } @else {
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.54);
        }
    }

    .message-box {
        &.success-light {
            background: mat.get-color-from-palette(mat.$green-palette, 100) !important;
            border-left-color: mat.get-color-from-palette(mat.$green-palette, 500) !important;
            color: map-get($foreground, text);
        }

        &.error-light {
            background: mat.get-color-from-palette(mat.$red-palette, 100) !important;
            border-left-color: mat.get-color-from-palette(mat.$red-palette, 500) !important;
            color: map-get($foreground, text);
        }
    }
}
