// -----------------------------------------------------------------------------------------------------
// @ Theming background & foreground helper classes
// -----------------------------------------------------------------------------------------------------

// For more details see:
// https://github.com/angular/components/blob/48ff4ba74a92924dc42ae2fd1592351c630fbb1b/src/lib/core/theming/_palette.scss#L667
// or node_modules/@angular/material/core/theming/_palette.scss
@mixin helpers-theme($theme) {
    // -----------------------------------------------------------------------------------------------------
    // @ BACKGROUND ATTRIBUTES
    // status-bar
    // app-bar
    // background
    // hover
    // card
    // dialog
    // disabled-button
    // raised-button
    // focused-button
    // selected-button
    // selected-disabled-button
    // disabled-button-toggle
    // unselected-chip
    // disabled-list-option
    // -----------------------------------------------------------------------------------------------------

    $background: map-get($theme, background);

    // -----------------------------------------------------------------------------------------------------
    // @ FOREGROUND ATTRIBUTES
    // base
    // divider
    // dividers
    // disabled
    // disabled-button
    // disabled-text
    // hint-text
    // secondary-text
    // icon
    // icons
    // text
    // slider-min
    // slider-off
    // slider-off-activ
    // -----------------------------------------------------------------------------------------------------

    $foreground: map-get($theme, foreground);

    // -----------------------------------------------------------------------------------------------------
    // @ Background helpers
    // -----------------------------------------------------------------------------------------------------

    @each $attribute, $color in $background {
        .#{$attribute}-bg {
            background-color: $color !important;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Foreground helpers
    // -----------------------------------------------------------------------------------------------------

    @each $attribute, $color in $foreground {
        .#{$attribute}-fg {
            color: $color !important;
        }
    }

    // Hover effect
    .hover {
        &:hover {
            background-color: map-get($background, hover);
        }
    }
}
