mat-icon {
    @for $size from 2 through 128 {
        &.s-#{$size * 2} {
            font-size: #{($size * 2) + 'px'} !important;
            width: #{($size * 2) + 'px'} !important;
            height: #{($size * 2) + 'px'} !important;
            min-width: #{($size * 2) + 'px'} !important;
            min-height: #{($size * 2) + 'px'} !important;
            line-height: #{($size * 2) + 'px'} !important;
        }
    }
}
