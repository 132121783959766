@use '@angular/material' as mat;

@mixin file-drag-n-drop-theme($theme) {
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    file-drag-n-drop {
        .dropzone {
            border-color: map-get($foreground, secondary-text);
            color: map-get($foreground, hint-text);

            &.fileover {
                background: map-get($background, hover);
            }
        }

        mat-icon.done-icon {
            color: mat.get-color-from-palette(mat.$green-palette, 400) !important;
        }
    }
}
