@use '@angular/material' as mat;

// Links
a {
    text-decoration: none;

    &:hover,
    &:active {
        text-decoration: underline;
    }
}

// Boxed text
.text-boxed {
    border-radius: 2px;
    line-height: normal;
    padding: 4px 8px;
    margin: 0 4px;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
}

// Message boxes
.message-box {
    padding: 16px;
    background: #607d8b;
    border: 1px solid #00080c;
    border-radius: 8px;
    color: rgba(255, 255, 255, 1);

    &.error {
        background: #ef5350;
        border-color: #b71c1c;
    }

    &.warning {
        background: #ffecb3;
        border-color: #ffc107;
        color: rgba(0, 0, 0, 0.87);
    }

    &.success {
        background: #4caf50;
        border-color: #2e7d32;
    }

    &.info {
        background: #b3e5fc;
        border-color: #03a9f4;
        color: rgba(0, 0, 0, 0.87);
    }
}
