@import '@angular/material/prebuilt-themes/indigo-pink.css';

// .navbar-folded is applied on <body> by NavbarComponent
.navbar-folded {
    #layout-vertical-navigation {
        margin-left: 64px;
    }
}

@import './app/app.scss';
