/* ----------------------------------------------------------------------------------------------------- */
/* @ Tailwind imports                                                                                    */
/* ----------------------------------------------------------------------------------------------------- */

@tailwind base;

/* This injects additional styles into Tailwind's base styles layer. */
@layer base {
    * {
        text-rendering: optimizeLegibility;
        -o-text-rendering: optimizeLegibility;
        -ms-text-rendering: optimizeLegibility;
        -moz-text-rendering: optimizeLegibility;
        -webkit-text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: transparent;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;

        box-sizing: border-box;

        &:before,
        &:after {
            box-sizing: border-box;
        }

        // Remove focus outline
        &:focus {
            outline: none;
        }
    }

    /* HTML and Body default styles */
    html {
        font-size: 16px;
        line-height: 1.5;
        font-family: 'Muli', 'Helvetica Neue', 'Arial', sans-serif;
        // font-family:
        //     Inter var,
        //     ui-sans-serif,
        //     system-ui,
        //     -apple-system,
        //     BlinkMacSystemFont,
        //     Segoe UI,
        //     Roboto,
        //     Helvetica Neue,
        //     Arial,
        //     Noto Sans,
        //     sans-serif,
        //     'Apple Color Emoji',
        //     'Segoe UI Emoji',
        //     Segoe UI Symbol,
        //     'Noto Color Emoji';
    }

    body {
        font-size: 0.875rem;
    }

    html,
    body {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;
        min-height: 100%;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }

    /* Make images and videos to take up all the available space */
    img {
        width: 100%;
        vertical-align: top;
    }

    input {
        border: none;

        /* Placeholder custom */
        &::placeholder {
            color: rgb(117, 117, 117);
            font-size: 13.3333px !important;
            font-family: Arial;
        }
    }


}

@tailwind components;
@tailwind utilities;
