// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------

@mixin colors-theme($theme) {
    $is-dark: map-get($theme, is-dark);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    // Base colors
    & {
        color: map-get($foreground, text);

        // If it's a light theme, use a darker background
        // color other than the default #fafafa
        @if ($is-dark) {
            background: map-get($background, background);
        } @else {
            background: #f5f5f5;
        }
    }

    .secondary-text {
        color: map-get($foreground, secondary-text);
    }

    .icon,
    i {
        color: map-get($foreground, icon);
    }

    .hint-text {
        color: map-get($foreground, hint-text);
    }

    .disabled-text {
        color: map-get($foreground, disabled-text);
    }

    .fade-text,
    .divider {
        color: map-get($foreground, divider);
    }
}
