.ps {
    position: relative;

    > .ps__rail-x {
        z-index: 900;
    }

    > .ps__rail-y {
        z-index: 900;
        left: auto !important;
    }
}
