@import 'styles/themes/colors.theme.scss';
@import 'styles/themes/helpers.theme.scss';
@import 'styles/themes/typography.theme.scss';

@mixin core-theme($theme) {
    @include colors-theme($theme);
    @include helpers-theme($theme);
    @include typography-theme($theme);

    // Group of text information. The title of the group (h2) is in the border/frame.
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .border-group {
        border: 1px solid map-get($foreground, divider);
        position: relative;
        border-radius: 2px;
        padding: 28px 16px 8px 16px;
        margin: 16px 0;

        h2 {
            background: map-get($background, dialog);
            color: map-get($foreground, secondary-text);

            position: absolute;
            top: -11px;
            left: 8px;
            margin: 0;
            padding: 0 8px;
            font-size: 16px;
            font-weight: 600;
        }

        h3 {
            color: map-get($foreground, secondary-text);
        }
    }
}
