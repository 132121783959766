@use '@angular/material' as mat;

@mixin fuse-navbar-theme($theme) {
    fuse-navigation {
        .nav-link {
            &.active {
                background-color: mat.get-color-from-palette(mat.$light-blue-palette, 600) !important;

                &.theme-blue-nav {
                    background-color: mat.get-color-from-palette(mat.$light-blue-palette, 600) !important;
                }

                &.theme-light-green-nav {
                    background-color: mat.get-color-from-palette(mat.$light-green-palette, 500) !important;
                }

                &.theme-indigo-nav {
                    background-color: mat.get-color-from-palette(mat.$indigo-palette, 500) !important;
                }

                &.theme-purple-nav {
                    background-color: mat.get-color-from-palette(mat.$purple-palette, 500) !important;
                }

                &.theme-green-nav {
                    background-color: mat.get-color-from-palette(mat.$teal-palette, 500) !important;
                }
            }
        }
    }
}
