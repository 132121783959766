@use '@angular/material' as mat;

@import '~leaflet/dist/leaflet.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

@import '~quill/dist/quill.snow.css';

@import './core/core.scss';

// Themes
@import './app.theme';

.material-icons {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

// -----------------------------------------------------------------------------------------------------
// @ Helpers
// -----------------------------------------------------------------------------------------------------

.add-button {
    position: fixed !important;
    z-index: 101;
    right: 32px;
    margin-right: 70px;
    margin-top: -35px;
}

// -----------------------------------------------------------------------------------------------------
// Material styles (overwrite)
// -----------------------------------------------------------------------------------------------------

// MatTable - Fix row height being shrunk with ngx-scrollbar
mat-table {

    mat-header-cell,
    mat-cell {
        box-sizing: content-box !important;
    }

    mat-row:last-of-type:not(.border-b-0) {
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    mat-row {
        flex-shrink: 0;
        height: unset !important;
        min-height: 52px;

        &:last-of-type:not(.border-b-0) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}

// MatMenu - Remove max width
.mat-mdc-menu-panel.no-max-width {
    max-width: none !important;
}

// Badge - Display overflow to show badge
mat-expansion-panel.badge-overflow-visible {
    overflow: visible;
}

mat-expansion-panel-header.badge-overflow-visible {
    &>.mat-content {
        overflow: visible;
    }
}

// Button (mat-icon-button) - Add Large & Medium & Small size
button {
    &.mat-mdc-icon-button {

        line-height: normal;

        // Large size
        &.lg-icon-button {
            height: 40px !important;
            width: 40px !important;
            line-height: 40px !important;
            padding: 4px !important;

            &>mat-icon {
                // s-24
                font-size: 24px !important;
                width: 24px !important;
                height: 24px !important;
                min-width: 24px !important;
                min-height: 24px !important;
                line-height: 24px !important;
            }
        }

        // Medium size
        &.md-icon-button {
            height: 32px !important;
            width: 32px !important;
            line-height: 32px !important;
            padding: 0px !important;

            &>mat-icon {
                font-size: 20px !important;
                width: 20px !important;
                height: 20px !important;
                min-width: 20px !important;
                min-height: 20px !important;
                line-height: 20px !important;
            }
        }

        // Small size
        &.sm-icon-button {
            height: 20px !important;
            width: 20px !important;
            line-height: 20px !important;
            padding: 0px !important;

            &>mat-icon {
                font-size: 14px !important;
                width: 14px !important;
                height: 14px !important;
                min-width: 14px !important;
                min-height: 14px !important;
                line-height: 14px !important;
            }
        }
    }
}

// Button toggle - Add Medium size
mat-button-toggle {

    // Medium size
    &.md-button-toggle {
        .mat-button-toggle-label-content {
            line-height: 32px;
        }
    }
}

// Vertical stepper - Adjust padding for content
mat-vertical-stepper {
    .mat-vertical-content {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
    }
}

// Mat Tabs - Fix header tabs to 56px
mat-tab-group {
    .mat-mdc-tab-body-wrapper {
        flex-grow: 1 !important;
    }

    .mdc-tab {
        height: 56px !important;
    }
}

mat-form-field {

    // Mat Form Field - Remove padding
    &.no-padding {
        .mat-mdc-form-field-wrapper {
            padding-bottom: 0;
        }
    }

    // Mat Form Field - Center content
    &.suffix-centered {
        .mat-mdc-form-field-flex {
            align-items: center;
        }
    }

    /* Remove the extra border on the right side of the notch */
    /* Tailwind's global border setter causes this issue */
    .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
            .mdc-notched-outline {
                .mdc-notched-outline__notch {
                    border-right-style: none !important;
                }
            }
        }
    }
}

mat-accordion {
    &.no-shadow {
        .mat-expansion-panel:not([class*='mat-elevation-z']) {
            box-shadow: none;
        }
    }

    .mat-expansion-panel {
        .mat-expansion-panel-header {
            .mat-expansion-indicator {
                &:after {
                    border-color: currentColor !important;
                }
            }
        }
    }
}

// Mat Tooltip - Update font size based on Material Design recommendations (https://material.io/components/tooltips#theming)
.mat-mdc-tooltip {
    font-size: 12px;
    white-space: pre-line; // Allow new lines
}

.form-field-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 360px), 1fr));
    grid-gap: 8px 16px;
}

.grid-cols-auto-fit {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr)) !important;
}

.theme-blue {
    & .mat-mdc-checkbox.mat-primary {
        --mdc-checkbox-selected-checkmark-color: white !important;
    }

    & .mat-mdc-button.mat-primary,
    & .mat-mdc-raised-button.mat-primary {
        --mdc-protected-button-label-text-color: white !important;
    }

    & .mat-mdc-fab.mat-primary,
    & .mat-mdc-mini-fab.mat-primary {
        --mat-mdc-fab-color: white !important;
    }
}

// Publication filters list
.mat-mdc-menu-item>.mdc-list-item__primary-text {
    font-size: 14px !important;
}

// Item selection
.mat-mdc-option .mdc-list-item__primary-text {
    font-size: 14px !important;
}

// Toolbar notification number badge position
#mat-badge-content-0 {
    top: 0 !important;
}

.mat-mdc-form-field-infix {
    display: flex;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
    top: -8px !important;
}
