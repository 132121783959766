@use '@angular/material' as mat;

@mixin widgets-theme($theme) {
    $accent: map-get($theme, accent);
    $accent-light: map-get($accent, 100);
    $foreground: map-get($theme, foreground);

    .border-bottom {
        border-color: map-get($accent, 100) !important;
    }

    .widget-home {
        &-title {
            background: mat.get-color-from-palette($accent) !important;
            color: white;
            a, span {
                color: white;
                transition: all ease-in-out .3s;
                &:hover {
                    color: map-get($accent, 100) !important;
                }
            }
        }
        &-list-item {
            &:hover {
                background: map-get($accent, 50) !important;
            }
        }
    }
}
