@use '@angular/material' as mat;
@use 'core/styles/variables/fuse-colors' as fuseColors;
@use 'sass:map';

// Modules
@import 'core/core.theme';
@import 'shared/shared.theme';
@import 'layout/layout.theme';

/* Include the core Angular Material styles */
@include mat.core();

// -----------------------------------------------------------------------------------------------------
// @ App theming
// -----------------------------------------------------------------------------------------------------

@mixin app-theme($theme) {
    $foreground: map-get($theme, foreground);

    mat-table {
        mat-row:last-of-type:not(.border-b-0) {
            border-color: map-get($foreground, divider);
        }
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Modules theming
// -----------------------------------------------------------------------------------------------------

@mixin modules-theme($theme) {
    @include app-theme($theme);

    // Angular
    @include core-theme($theme);
    @include shared-theme($theme);
    @include layout-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

/* Create a base theme without any color to set the density and typography */
@include mat.all-component-themes(
    (
        color: null,
        density: 0,
        typography:
            mat.define-typography-config(
                $font-family: 'Muli, Helvetica Neue, Arial, sans-serif',
                $headline-6: mat.define-typography-level(20px, 32px, 600),
                $subtitle-2: mat.define-typography-level(14px, 24px, 600),
                $button: mat.define-typography-level(14px, 14px, 600),
                $body-1: mat.define-typography-level(16px, 1.125, 400)
            )
    )
);

// -----------------------------------------------------------------------------------------------------
// @ Themes
// -----------------------------------------------------------------------------------------------------

$themes: (
    light-green: (
        selector: '.theme-light-green',
        primary: mat.define-palette(mat.$light-green-palette),
        accent: mat.define-palette(mat.$blue-grey-palette),
        warn: mat.define-palette(mat.$red-palette)
    ),
    light-blue: (
        selector: '.theme-blue',
        primary: mat.define-palette(mat.$light-blue-palette, 600),
        accent: mat.define-palette(mat.$blue-grey-palette),
        warn: mat.define-palette(mat.$red-palette)
    ),
    green: (
        selector: '.theme-green',
        primary: mat.define-palette(mat.$teal-palette),
        accent: mat.define-palette(mat.$blue-grey-palette),
        warn: mat.define-palette(mat.$red-palette)
    ),
    purple: (
        selector: '.theme-purple',
        primary: mat.define-palette(mat.$purple-palette),
        accent: mat.define-palette(mat.$blue-grey-palette),
        warn: mat.define-palette(mat.$red-palette)
    ),
    indigo: (
        selector: '.theme-indigo',
        primary: mat.define-palette(mat.$indigo-palette),
        accent: mat.define-palette(mat.$blue-grey-palette),
        warn: mat.define-palette(mat.$red-palette)
    ),
    blue-grey: (
        selector: '.theme-blue-grey',
        primary: mat.define-palette(mat.$blue-grey-palette),
        accent: mat.define-palette(mat.$blue-grey-palette),
        warn: mat.define-palette(mat.$red-palette)
    ),
    fuse-navy: (
        selector: '.theme-fuse-navy',
        primary: mat.define-palette(fuseColors.$fuse-navy),
        accent: mat.define-palette(mat.$blue-grey-palette),
        warn: mat.define-palette(mat.$red-palette)
    )
);

/* Loop through themes and generate Angular Material themes */
@each $name, $theme in $themes {
    /* Generate the palettes */
    $palettes: ();
    @each $name in (primary, accent, warn) {
        /* Get the Angular Material theme */
        $palette: map.get($theme, $name);

        $palettes: map.merge($palettes, (#{$name}: $palette));
    }

    /* Define a light & dark Angular Material theme with the generated palettes */
    $light-theme: mat.define-light-theme(
        (
            color: $palettes
        )
    );

    $dark-theme: mat.define-dark-theme(
        (
            color: $palettes
        )
    );

    /* Generate and encapsulate Angular Material themes */
    #{map.get($theme, selector)} {
        @include fuse-color-classes($palettes);

        &.light {
            @include mat.all-component-colors($light-theme);
            @include modules-theme($light-theme);
        }

        &.dark {
            @include mat.all-component-colors($dark-theme);
            @include modules-theme($dark-theme);
        }
    }
}
